var maps = {

	apiKey: WP.gmaps_api_key,
	debug: false,
	allMaps: [],
	initialised: false,
	requestedAPI: false,
	gotAPI: false,

	addMaps: function() {
		var self = this;
		
		var locations = JSON.parse(WP.map_locations);
	
		var options = {
			image: {
				url: WP.template_path + '/images/map-pin.svg',
				width: 34,
				height: 45
			},
			autoSizeMap: true,
			mapId: 'YOUR_MAP_STYLE_ID'  // Add your map style ID here
		};
	
		self.createMap($('.js-map'), locations, options, function(map) {
			// map is now loaded
		});

	},

	loadAPI: function() {
		var self = this;
		self.requestedAPI = true;
		var jsFile = document.createElement('script');
		jsFile.src = 'https://maps.googleapis.com/maps/api/js?callback=clickyCreateMaps&key=' + self.apiKey;
		document.getElementsByTagName('head')[0].appendChild(jsFile);
	},

	createMap: function(el, locations, options, callback) {
		var self = this;

		// if we don't have the API yet, add the map to the
		// allMaps array, otherwise initialise the map
		if (!self.gotAPI) {
			
			self.addMap(el, locations, options, callback);

		} else {

			var _instances = [];

			// set some default options
			var defaults = {
				center: {
					lat: locations[0].lat,
					lng: locations[0].lng
				},
				zoom: 15,
				image: false,
				scrollwheel: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				mapId: options.mapId  // Use the mapId from options
			};

			// merge the given options with the defaults
			var settings = $.extend({}, defaults, options);

			// create an API friendly options object
			var mapOptions = {
				center: new google.maps.LatLng(settings.center.lat, settings.center.lng),
				zoom: settings.zoom,
				scrollWheel: settings.scrollWheel,
				mapTypeId: settings.mapTypeId,
				mapId: settings.mapId  // Include the mapId in the map options
			};

			// Create map for each container element
			el.each(function(i, el) {

				// initialise map and infowindow popup
				var map = new google.maps.Map(el, mapOptions);
				var infoWindow = new google.maps.InfoWindow();
				var markerBounds = new google.maps.LatLngBounds();

				// create a property to store all of the markers for the map
				map.allMarkers = [];
				
				i = 0;

				// create markers for each location using an interval
				var interval = setInterval(function () {

					// store the current location for easy reference
					var currentLocation = locations[i];
					var markerPosition = new google.maps.LatLng(currentLocation.lat, currentLocation.lng);

					// define the marker options
					var markerOptions = {
						position: markerPosition,
						map: map,
						animation: google.maps.Animation.DROP
					};

					// initialise the marker
					var marker = new google.maps.Marker(markerOptions);

					// store it in the array within the map instance
					map.allMarkers.push(marker);

					if(settings.autoSizeMap == true) {
						markerBounds.extend(markerPosition);
					}

					i++;

					// if we're on the last location, clear the interval
					if (i == locations.length) {
						clearInterval(interval);
						if (settings.autoSizeMap == true && locations.length > 1) {
							map.fitBounds(markerBounds);
						}
					}

				}, 200);

				// re-center map on window resize
				google.maps.event.addDomListener(window, "resize orientationchange", function() {
					var center = map.getCenter();
					google.maps.event.trigger(map, "resize");
					map.setCenter(center);
				});

				self.debugInfo('Map created', map);

				_instances.push(map);
			});

			var result = _instances;

			if (result.length == 1) {
				result = _instances[0];
			}

			callback(result);

			return result;
		}
	},

	addMap: function(el, locations, options, callback) {
		var self = this;

		// make sure the container argument is a jquery object
		if (!(el instanceof jQuery)) {
			el = $(el);
		}
	
		// if there is more than one container on the page
		if (el.length > 0) {

			// push a new map config into the allMaps array
			self.allMaps.push({
				el: el,
				locations: locations,
				options: options,
				callback: callback
			});

			// if we haven't requested the API yet but we've already done the
			// inital setup, create the callback function and get the API
			if (!self.requestedAPI && self.initialised) {
				self.debugInfo('New map initialised');
				self.createCallback();
				self.loadAPI();
			}
		}
	},

	createCallback: function() {
		var self = this;

		window.clickyCreateMaps = function() {
			self.debugInfo('API loaded');
			self.gotAPI = true;

			// loop through all maps initialised so far and create the map
			$.each(self.allMaps, function(i, _map) {
				self.createMap(_map.el, _map.locations, _map.options, _map.callback);
			});

		};
		self.debugInfo('Callback function created');
	},

	debugInfo: function() {
		var self = this;
		if (self.debug) {
			console.log.apply(null, arguments);
		}
	},

	init: function() {
		var self = this;

		// add initial maps to the allMaps array
		self.addMaps();
		self.initialised = true;
		var plural = self.allMaps.length == 1 ? '' : 's';
		self.debugInfo(self.allMaps.length + ' map' + plural + ' initialised');

		// if we have maps and haven't requested the API yet, create
		// the callback function and get the API
		if (self.allMaps.length > 0 && !self.requestedAPI) {
			self.createCallback();
			self.debugInfo('API requested');
			self.loadAPI();
		}
	}

};

module.exports = maps;
