var scrollTo = {
    setUpScrollTo: function () {
        $('[href^="#"]').on('click', function (e) {
            e.preventDefault();
            var target = $(this).attr('href');
            var screenWidth = $(window).width();
            var headerHeight = 100;
            if(screenWidth > 1024) {
                headerHeight = 140;
            }

            if (target != '#') {
                var top = $(target).offset().top - headerHeight;
                $('html, body').animate({
                    scrollTop: top
                }, 1000);
            }
        });
    },
    init: function () {
        this.setUpScrollTo();
    }
};

module.exports = scrollTo;
