const pageScroll = require('../utils/pageScroll');
const events = require('./events');
const stateClasses = require('../utils/stateClasses');

const classes = {
    mainNav: 'js-primary-nav',
    menuToggle: 'js-primary-nav-toggle',
    menuClose: 'js-menu-close',
    linksHaveSubMenu: '.menu-item-has-children',
    subMenu: 'sub-menu',
    menuItem: 'menu-item',
    menuItemHasChildren: 'menu-item-has-children',
    subMenuToggle: 'js-sub-menu-toggle',
};

let responsiveMenuBreakpoint = 1025;

var primaryNavigation = {

    toggleMenu () {
        var self = this;
        $(`.${classes.menuToggle}`).on('click', function(e){
            // Click event to toggle mobile nav
            let $menuToggle = $(this);
            e.preventDefault();
            if ($menuToggle.attr('aria-expanded') != 'true') {
                pageScroll.lock();
                $menuToggle.attr('aria-expanded', 'true');
                $(`.${classes.menuClose}`).attr('aria-expanded', 'true');
            } else {
                pageScroll.unlock();
                $menuToggle.attr('aria-expanded', 'false');
                $(`.${classes.menuClose}`).attr('aria-expanded', 'false');
            }
            self.responsiveMenuOpen = !self.responsiveMenuOpen;
        });
    },
    closeMenu () {
        var self = this;
        $(`.${classes.menuClose}`).on('click', function(e){
            // Click event to toggle mobile nav
            let $menuToggle = $(`.${classes.menuToggle}`);
            e.preventDefault();
            pageScroll.unlock();
            $menuToggle.attr('aria-expanded', 'false');
            $(`.${classes.menuClose}`).attr('aria-expanded', 'false');
            self.responsiveMenuOpen = false;
        });
    },
    animateSubMenu () {
        var self = this;
        $(`.${classes.subMenuToggle}`).on('click', function(e){
            console.log('click');
            let $subMenuToggle = $(this);
            let $menuItem = $subMenuToggle.closest(`.${classes.menuItem}`);
            if (window.innerWidth <= responsiveMenuBreakpoint) {
                if ($menuItem.hasClass(classes.menuItemHasChildren)) {
                    e.preventDefault();
                    if ($subMenuToggle.attr('aria-expanded') == 'true') {
                        $subMenuToggle.attr( 'aria-expanded', 'false' );
                    } else {
                        $subMenuToggle.attr( 'aria-expanded', 'true' );
                    }
                } else {
                    // You clicked a link
                }
            }
        });
    },
    checkResponsiveWidth () {
        var self = this;
        if (window.innerWidth >= responsiveMenuBreakpoint) {
            // TODO: rewrite if statement.
            // if (globalElements.$body.hasClass(stateClasses.isOpen)) {
            //     pageScroll.unlock();
            //     self.responsiveMenuOpen = false;
            // }
        }
    },

    tabAway: function() {
        $(`.${classes.mainNav} > li:last-child > a`).each( function( i, linkEl ) {
            linkEl.addEventListener( 'blur', function( event ) {
                if ( ! $(`.${classes.mainNav}`).contains( event.relatedTarget ) ) {
                    $(`.${classes.menuToggle}`).setAttribute( 'aria-expanded', 'false' );
                }
            } );
        } );
    },

    closeMenuOnUnload() {
        pageScroll.unlock();
        $(`.${classes.menuToggle}`).attr('aria-expanded', 'false');
        $(`.${classes.menuClose}`).attr('aria-expanded', 'false');
        this.responsiveMenuOpen = false;
    },

    init: function() {
        var self = this;

        self.toggleMenu();
        self.closeMenu();
        self.animateSubMenu();
        self.tabAway();

        events.add('resize', self.checkResponsiveWidth);

        window.addEventListener('pagehide', function(event) {
            self.closeMenuOnUnload();
        });

    }
};

module.exports = primaryNavigation;