const vehicleGrid = 'vehicle-grid';
const urlParams = new URLSearchParams(window.location.search);
const paramsToCheck = ['fuel_type', 'price_range', 'car_type'];
const headerHeight = 130;

var vehicleFilter = {
  scrollToIDIfParameterExists: function() {
    paramsToCheck.forEach(param => {
      const paramValue = urlParams.get(param);
      if (paramValue !== null) {
        const targetElement = document.getElementById(vehicleGrid);
        if (targetElement) {

            var top = $(targetElement).offset().top - headerHeight;
            $('html, body').animate({
                scrollTop: top
            }, 1000);
        }
      }
    });
  },

  init: function() {
    this.scrollToIDIfParameterExists();
  }
};

module.exports = vehicleFilter;
  