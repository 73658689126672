var objectFitImages = require('object-fit-images');
var onImageLoad = require('../utils/onImageLoad');

var images = {

	polyfillObjectFitImages: function() {

		// non-lazy loading images
		$('img.u-cover:not([loading="lazy"])').each(function(i, image) {
			onImageLoad(image, function() {
				objectFitImages(image);
			});
		});

		// lazy loading images
		$('img.u-cover[loading="lazy"]').each(function(i, image) {
			$(image).on('lazyloaded', function() {
				objectFitImages(image);
			});
		});
	},

	init: function() {

		if (Modernizr && !Modernizr.objectfit) {
			this.polyfillObjectFitImages();
		}

	}

};

module.exports = images;