
const recaptchaLoader = {
    init: function () {
        let loadedCaptcha = false;

        $('input, textarea, select', 'form[method="POST"]').on('focus', function () {
            // Check if recaptcha script has been loaded already
            if (loadedCaptcha) {
                return;
            }

            // Load recaptcha script
            $.getScript( "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" )
                .done(function( script, textStatus ) {
                    loadedCaptcha = true;
                })
                .fail(function( jqxhr, settings, exception ) {
                    console.log("Failed to load recaptcha script");
                });
        });
    }
};

module.exports = recaptchaLoader;

