const eventFunctions = {
    'scroll': [],
    'resize': [],
    'escape': [],
    'leftArrow': [],
    'rightArrow': [],
};

const events = {
    add: function (event, fn) {
        eventFunctions[event].push(fn);
        return eventFunctions;
    },
    get: function (event) {
        return eventFunctions[event];
    },
    remove: function(event, fn) {
        const thisEventFunctions = eventFunctions[event];
        const index = thisEventFunctions.indexOf(fn);
        thisEventFunctions.splice(index, 1);
        eventFunctions[event] = thisEventFunctions;
        return eventFunctions;
    },
    addKeyupEvents: function () {
        window.addEventListener('keyup', (e, fn) => {
            e = e || window.event;
            // escape 
            if (e.keyCode == 27) {
                eventFunctions.escape.forEach(fn => {
                    fn();
                });
            }
            // left arrow
            if (e.keyCode == 37) {
                eventFunctions.leftArrow.forEach(fn => {
                    fn();
                });
            }
            // right arrow
            if (e.keyCode == 39) {
                eventFunctions.rightArrow.forEach(fn => {
                    fn();
                });
            }
        });
    },
    addResizeEvents: function () {
        window.addEventListener('resize', (e, fn) => {
            eventFunctions.resize.forEach(fn => {
                fn();
            });
        });
    },
    addScrollEvents: function () {
        let runningScrollEventFunctions = false;
        window.addEventListener('scroll', fn => {
            const scrollPos = window.pageYOffset;
            if (!runningScrollEventFunctions) {
                requestAnimationFrame(() => {
                    eventFunctions.scroll.forEach(fn => {
                        fn(scrollPos);
                    });
                    runningScrollEventFunctions = false;
                });
                runningScrollEventFunctions = true;
            }
        });
    },
    init: function () {
        const self = this;
        self.addKeyupEvents();
        self.addResizeEvents();
        self.addScrollEvents();
    }
};

module.exports = events;