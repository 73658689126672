module.exports = function (el, offset) {
    if (el instanceof jQuery) {
        el = el.get(0);
    }
    offset = offset ? offset : 0;
    var bounding = el.getBoundingClientRect();
    return (
        (bounding.top >= 0 && bounding.top <= (window.innerHeight + offset)) ||
        (bounding.bottom >= 0 && bounding.bottom <= (window.innerHeight + offset)) ||
        (bounding.top < 0 && bounding.bottom > (window.innerHeight + offset))
    );
};