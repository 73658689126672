var debounce = require('../utils/debounce');
var stateClasses = require('../utils/stateClasses');

var classes = {
    container : 'js-video-container',
    player : 'js-youtube-player',
    videoPlaying : stateClasses.isPlaying
};

var youtube = {
    getVideoSize: function(player, parent, customRatio) {
        var videoSize = [];

        var parentEl = parent || $(player.a).parent();
        if (!(parentEl instanceof jQuery)) {
            parentEl = $(parentEl);
        }
        var parentWidth = parentEl.outerWidth();
        var parentHeight = parentEl.outerHeight();
        var parentRatio = parentWidth / parentHeight;
        var videoWidth;
        var videoHeight;
        var ratioX = 16;
        var ratioY = 9;
        if (customRatio) {
            ratioX = customRatio.x;
            ratioY = customRatio.y;
        }
        var ratio = ratioX / ratioY;
        if (parentRatio >= ratio) { // fit to width
            videoWidth = parentWidth;
            videoHeight = parentWidth / ratio;
        } else { // fit to height
            videoHeight = parentHeight;
            videoWidth = (parentHeight / ratioY) * ratioX;
        }

        videoSize.width = videoWidth;
        videoSize.height = videoHeight;

        return videoSize;
    },

    setVideoSize: function(player, videoSize) {
        player.setSize(videoSize.width, videoSize.height);
    },

    init: function () {
        var self = this;
        $(`.${classes.player}`).each(function () {
            var playerEl = this; // a reference to the player div
            var $containerEl = $(playerEl).parents(`.${classes.container}`); // the container div
            var youtubeId = $(playerEl).data('video-id'); // the ID of the YouTube video
            var autoPlay = $(playerEl).data('autoplay'); // Autoplay data att
            var playerOptions = {
                videoId: youtubeId,
                playerVars: {
                    autoplay: (typeof autoPlay !== 'undefined') ? autoPlay : 0,
                    rel: 0,
                },
                events: {
                    onStateChange: function (e) {
                        var player = e.target;

                        // when playing starts, add a class to the parent
                        if (e.data === 1) {
                            $containerEl.addClass(classes.videoPlaying);
                        } else if (e.data === 0) {
                            $containerEl.removeClass(classes.videoPlaying);
                        }
                    },
                    onReady: function (e) {
                        var player = e.target;
                        var videoSize = self.getVideoSize(player);
                        self.setVideoSize(player, videoSize);
                        $(window).on('resize', debounce(function () {
                            var videoSize = self.getVideoSize(player);
                            self.setVideoSize(player, videoSize);
                        }, 250));
                    }
                }
            };

            $(playerEl).parents(`.${classes.container}`).on('click', function(e) {
                if (! $(this).hasClass(classes.videoPlaying)) {
                    player.playVideo();
                }
            });

            var player = new YT.Player(playerEl, playerOptions);
            
        });
    }

};

module.exports = youtube;
