require('jquery-validation');

var validation = {

	addCustomMethods: function() {
		jQuery.validator.addMethod("defaultInvalid", function (value, element, errorMessage) {
			return element.value != element.defaultValue;
		}, 'Please enter a value');
		jQuery.validator.addClassRules('required', {
			required: true
		});
		jQuery.validator.addClassRules('validate-email', {
			email: true
		});
		jQuery.validator.addMethod( "phoneUK", function( phone_number, element ) {
			phone_number = phone_number.replace( /\(|\)|\s+|-/g, "" );
			return this.optional( element ) || phone_number.length > 9 &&
				phone_number.match( /^(?:(?:(?:00\s?|\+)44\s?)|(?:\(?0))(?:\d{2}\)?\s?\d{4}\s?\d{4}|\d{3}\)?\s?\d{3}\s?\d{3,4}|\d{4}\)?\s?(?:\d{5}|\d{3}\s?\d{3})|\d{5}\)?\s?\d{4,5})$/ );
		}, "Please specify a valid phone number." );
		return true;
	},

	setUpForms: function() {
		// Add required class to all fields with required attribute
		$('[required]').addClass('required');

		// Validate forms
		$('.js-form').each(function () {
			$(this).validate({
				errorElement: 'span',
				rules: {
					enquiry_phone_number: {
						phoneUK: true
					}
				},
				submitHandler: function(form, e) {
			        e.preventDefault();
			        var $captchaEl = $(form).find('.g-recaptcha');
			        var $captchaResponseEl = $(form).find('.g-recaptcha-response');
			        if ($captchaResponseEl.length > 0) {
			            var captchaID = $captchaEl.data('widget_id');
			            grecaptcha.execute(captchaID);
			        } else {
						if(WP.gtm_custom_events.length > 0) {
							WP.gtm_custom_events.each(function(formId, custom_event_name) {
								console.log(custom_event_name);
								if ($(el).parents('form').attr('id') == formId) {
									window.dataLayer.push({
										'event': custom_event_name
									});
								}
							});
						}
			            form.submit();
			        }
			    },
				onfocusout: function (element) { $(element).valid(); },
				onkeyup: function(element,event) { 
					if(event.which != 9) {
						$(element).valid();
					}
				}, //on keyup validate except the tab key
				onclick: function (element) {
					$(element).valid();
				},
			});
		});
	},

	init: function() {
		this.addCustomMethods();
		this.setUpForms();
	}
	
};

module.exports = validation;