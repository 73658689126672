var tooltips = {
    toggleTooltip() {
        $(document).on('click', '.js-tooltip', function(e) {
            e.preventDefault();
            const $tooltip = $(this).next('.js-tooltip-content');
            $('.js-tooltip-content').not($tooltip).removeClass('active');
            $tooltip.addClass('active');
        });
    },

    closeTooltip() {
        $(document).on('click', '.js-tooltip-close', function(e) {
            e.preventDefault();
            const $tooltipContent = $(this).closest('.js-tooltip-content');
            $tooltipContent.removeClass('active');
        });
    },

    init() {
        this.toggleTooltip();
        this.closeTooltip();
    },
};

module.exports = tooltips;