var currentWindowPageYOffset;

var pageScroll = {
    lock: function () {
        currentWindowPageYOffset = window.pageYOffset;
        document.body.style.position = 'fixed';
        document.body.style.top = -(currentWindowPageYOffset) + 'px';
    },
    unlock: function () {
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('top');
        currentWindowPageYOffset = currentWindowPageYOffset ? currentWindowPageYOffset : window.pageYOffset;
        window.scrollTo(0, currentWindowPageYOffset);
    },
};

module.exports = pageScroll;