const getIndex = require('../utils/getIndex');
const stateClasses = require('../utils/stateClasses');
const events = require('./events');

const $galleries = $('.js-gallery');

const classes = {
    item: 'js-gallery-item',
    overlay: 'js-gallery-overlay',
    overlayImage: 'js-gallery-overlay-image',
    button: 'js-gallery-button',
    close: 'js-gallery-close',
};

const $closeButtons = $(`.${classes.close}`);

var gallery = {

    closeOverlays: function() {
        $(`.${classes.overlay}`).removeClass(stateClasses.isOpen);
    },

    build: function() {

        // loop through all galleries
        $galleries.each(function() {
            
            const $gallery = $(this);
            const $overlay = $gallery.find(`.${classes.overlay}`);
            const $overlayImage = $gallery.find(`.${classes.overlayImage}`);
            const $navButtons = $gallery.find(`.${classes.button}`);

            let index;
            let $items = $gallery.find(`.${classes.item}`);

            // add a custom event listener to the gallery
            $gallery.on('galleryLoadMore', () => {
                $items = $gallery.find(`.${classes.item}`);
            });

            // event delegation to detect clicks on the gallery and run function if event target is itemClass
            $gallery.on('click', `.${classes.item}`, function(e) {

                // stop the browser navigating to the image URL
                e.preventDefault();

                // update the index variable
                index = $items.index(this);

                // grab the src of the clicked item
                var newSrc = $(this).attr('href');

                // hide the image to prevent showing the previous one, and apply the new src to it
                $overlayImage.hide().attr('src', newSrc);

                // fade the overlay in
                $overlay.addClass(stateClasses.isOpen);

            });

            // fade the overlay image in whenever the load event fires
            $overlayImage.get(0).onload = function() {
                $overlayImage.fadeIn();
            };

            // fade the overlay out when it's clicked
            $overlay.on('click', function(e) {
                e.stopPropagation();
                $overlay.removeClass(stateClasses.isOpen);
            });

            const showPrevImage = function() {
                index = getIndex('prev', $items, index);
                updateImageSrc();
            };
            
            const showNextImage = function() {
                index = getIndex('next', $items, index);
                updateImageSrc();
            };

            const updateImageSrc = function() {

                // get the src
                const newSrc = $items.eq(index).attr('href');

                // fade the overlay image out, then change the src
                $overlayImage.fadeOut(function() {
                    $overlayImage.attr('src', newSrc);
                });

            };

            // handle nav button clicks
            $navButtons.on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();

                // get the next/prev index
                const direction = $(this).data('direction');
                index = getIndex(direction, $items, index);

                // update the src
                updateImageSrc();
                
            });

            events.add('leftArrow', showPrevImage);
            events.add('rightArrow', showNextImage);
        });
    },
    init: function() {
        var self = this;
        self.build();
        events.add('escape', self.closeOverlays);
        $closeButtons.on('click', self.closeOverlays);
    },
};

module.exports = gallery;