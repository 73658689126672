// Load jquery and assign to window object
window.$ = window.jQuery = require('jquery');
require('focus-visible');

// Load files
var validator = require('./components/validation');
var sliders = require('./components/sliders');
var animations = require('./components/animations');
var images = require('./components/images');
var maps = require('./components/maps');
var gallery = require('./components/gallery');
var youTubeIframeLoader = require('youtube-iframe');
var youtube = require('./components/youtube');
var vimeo = require('./components/vimeo');
var accordions = require('./components/accordions');
var scrollTo = require('./components/scrollTo');
var events = require('./components/events');
var overlays = require('./components/overlays');
var primaryNavigation = require('./components/primaryNavigation');
var resultsFilter = require('./components/resultsFilter');
var copyLink = require('./components/copyLink');
var recaptchaLoader = require('./components/recaptcha');
var tooltips = require('./components/tooltips');
var vehicleFilter = require('./components/vehicleFilter');

// On DOM ready
$(function() {

	// Image loading and resizing
	images.init();

	// Sliders
	sliders.init();

	// Recaptcha Loader
	recaptchaLoader.init();

	// Form Validation
	validator.init();

	// Maps
	maps.init();

	// Gallery
	gallery.init();

	// Accordions
	accordions.init();

	// Scroll To
	scrollTo.init();

	// Events
	events.init();

	// Overlays
	overlays.init();

	// Animations
	animations.init();

	// Vimeo
	vimeo.init();

	// Copy Link
	copyLink.init();

	// Primary Navigation
	primaryNavigation.init();

	// Results Filter
	resultsFilter.init()

	// Tooltips
	tooltips.init();

	// Vehicle Filter
	vehicleFilter.init();

	// YouTube Iframe Loader
	if ($('.js-video-container').length) {
		youTubeIframeLoader.load(function () {
			youtube.init();
		});
	}

});