const isInViewport = require('../utils/isInViewport');
const events = require('./events');

const $elements = $('[data-animation]');

const animations = {
	running: false,
	track: function () {
		const $unanimatedElements = $elements.filter(function () {
			if (typeof $(this).data('animated') != 'undefined') {
				return false;
			}
			return true;
		});
		$unanimatedElements.each(function (i, el) {
			const $el = $(el);
			// Get the offset, or set the default fallback
			const offset = $el.data('animation-offset') ? $el.data('animation-offset') : -100;
			if (isInViewport(el, offset)) {
				$el.addClass('animated').data('animated', true);
			}
		});
	},
	init: function() {
		const self = this;
		if (self.running) {
			self.track();
			events.add('scroll', self.track);
		}
	}

};

module.exports = animations;