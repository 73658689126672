require('slick-carousel');

var sliders = {
	
	init: function() {
		$('.js-slider').on('init', function() {
			// function to run when slider is initialised
		}).slick({
			rows: 0,
			dots: true
		});

		$('.js-banner-slider').each(function() {
			var $slider = $(this);
			var settings = {
				rows: 0,
				arrows: false,
				dots: true,
				slidesToShow: 1,
				fade : true,
				autoplay: true,
				autoplaySpeed: 7000,
				speed : 700,
			};
			$(this).slick(settings);
		});

		$('.js-testimonial-slider').each(function() {
			var $slider = $(this);
			var settings = {
				rows: 0,
				arrows: false,
				dots: true,
				slidesToShow: 1,
				infinite: true,
				speed : 700,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 3
						}
					}
				]
			};
			$(this).slick(settings);
		});

		// Latest News module slider
		$('.js-latest-news-slider').each(function() {
			var $slider = $(this);
			var settings = {
				dots: true,
				arrows: false,
				slidesToShow: 1,
				responsive: [
					{
						breakpoint: 5000,
						settings: "unslick"
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: true,
						}
					}
				]
			};
			$(this).slick(settings);
		});

		// Vehicles Grid module slider
		$('.js-vehicles-grid-slider').each(function() {
			var $vehiclesGridSlider = $(this);
			var settings = {
				dots: true,
				arrows: false,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 800,
						settings: "unslick"
					}
				]
			};
			const vehicleSlider = $(this).slick(settings);
			$(window).on('resize', function () {
				if ($(window).width() < 800 && !vehicleSlider.hasClass('slick-initialized')) {
					$('.js-vehicles-grid-slider').slick(settings);
				}
			});
		});

		$('.js-vehicle-image-slider').each(function() {
			var $slider = $(this);
			var settings = {
				rows: 0,
				arrows: true,
				dots: true,
				slidesToShow: 1,
				infinite: true,
				speed : 700,
				mobileFirst: true,
				nextArrow: '<div class="arrow next-arrow"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g stroke-width="1" transform="translate(0, 0)"><polyline points="3.5 0.5 9.5 6 3.5 11.5" fill="none" stroke="#444444" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></polyline></g></svg></div>',
				prevArrow: '<div class="arrow prev-arrow"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g stroke-width="1" transform="translate(0, 0)"><polyline points="8.5 11.5 2.5 6 8.5 0.5" fill="none" stroke="#444444" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></polyline></g></svg></div>',
			};
			$(this).slick(settings);
		});

	}

};

module.exports = sliders;