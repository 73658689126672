var copyLink = {
    init: function () {
        var $temp = $("<input>");
        var $url = $(location).attr('href');
        var $copyMessage = $('.js-clipboard__copy-message');
        $('.js-clipboard').on('click', function() {
            $("body").append($temp);
            $temp.val($url).select();
            document.execCommand("copy");
            $copyMessage.css('opacity', '1');

            $temp.remove();

            setTimeout(function() {
                $copyMessage.css('opacity', '0');
            }, 2000);
          });
    }
};

module.exports = copyLink;