var Vimeo = require('@vimeo/player');
var throttle = require('../utils/throttle');
var stateClasses = require('../utils/stateClasses');

const classes = {
    container : 'js-video-container',
    player : 'js-vimeo-player',
    videoPlaying : stateClasses.isPlaying
};

var vimeoVideos = {

    /**
     * This function calculates and returns required video dimensions to fill a parent element.
     * It takes an element and calculates its ratio based on its height and width.
     * It then compares this ratio to a 16:9 ratio and will then return new dimensions to ensure the video occupies the full element space.
     * @param {element} $container The element to get the dimensions of
     * @return {Object} An object containing height and width dimensions.
     */
    getVideoDimensions: function($container) {
        let parentWidth = $container.outerWidth();
        let parentHeight = $container.outerHeight();
        let parentRatio = parentWidth / parentHeight;
    
        let videoWidth;
        let videoHeight;
    
        let ratio = 16 / 9;
    
        if (parentRatio >= ratio) {
            // fit to width
            videoWidth = parentWidth;
            videoHeight = parentWidth / ratio;
        } else {
            // fit to height
            videoHeight = parentHeight;
            videoWidth = (parentHeight / 9) * 16;
        }
        return {
            width: videoWidth,
            height: videoHeight
        };
    },

    /**
     * This function takes a Vimeo player instance and sets an interval to check if the video is ending.
     * If the video is ending it restarts the video position to the start of the video.
     * This is to avoid the "flash" that occurs when looping/autoplaying with Vimeo.
     * @param {vimeo} vimeoPlayer The Vimeo player instance
     */
    restartPlayerBeforeFinish: function(vimeoPlayer) {
        let duration = vimeoPlayer.getDuration();
        setInterval(function() {
            vimeoPlayer.getCurrentTime().then(function(seconds) {
                if (seconds > duration - 1) {
                    vimeoPlayer.setCurrentTime(0);
                }
            });
        }, 250);
    },

    /**
     * This function sets a given player element to the given dimensions.
     * @param {element} $playerEl The element of the vimeo player that should have it's dimensions set.
     * @param {Object} videoDimensions An object containing the dimensions the player element will be set to.
     */
    setPlayerDimensions: function($playerEl, videoDimensions) {
        $playerEl.css({
            'height': videoDimensions.height,
            'width': videoDimensions.width,
        });
    },

    /**
     * This function sets up and returns the player options needed to create an instance of a vimeo player.
     * It sets the player options based on the player element and video dimensions provided. 
     * @param {element} $playerEl The element of the vimeo player that should have it's dimensions set.
     * @param {Object} videoDimensions An object containing the dimensions the player will be set up with.
     * @return {Object} An object containing the player options for the given player element
     */
    getPlayerOptions: function($playerEl, videoDimensions) {
        let playerOptions = {
            id : $playerEl.data('video-id'),
            width: videoDimensions.width,
            height: videoDimensions.height,
        };

        if ($playerEl.data('autoplay') == 1) {
            playerOptions.loop = true;
            playerOptions.autoplay = true;
            playerOptions.muted = true;
            playerOptions.background = true;
            playerOptions.autopause = false;
        } else {
            playerOptions.loop = false;
            playerOptions.autoplay = false;
            playerOptions.muted = false;
        }

        return playerOptions;
    },

    /**
     * This function listens for different events on a given player dependant on if the player autoplays.
     * It then handles those events and triggers any relevant functions if necessary.
     * @param {element} $playerEl The element of the vimeo player that should have it's dimensions set.
     * @param {element} $containerEl The containing element of the vimeo player that should have it's dimensions set.
     * @param {vimeo} vimeoPlayer The vimeo player instance that will have event listeners set on it
     */
    setupEventListeners: function($playerEl, $containerEl, vimeoPlayer) {
        var self = this;

        if ($playerEl.data('autoplay') == 1) {
            vimeoPlayer.on('ended', function() {
                 vimeoPlayer.play();
            });

            vimeoPlayer.ready().then(function(e) {
                $containerEl.addClass(classes.videoPlaying);
                vimeoPlayer.play();
                self.restartPlayerBeforeFinish(vimeoPlayer);
            });
        } else {
            $containerEl.on('click', function(e) {
                if (! $(this).hasClass(classes.videoPlaying)) {
                    vimeoPlayer.play();
                    $containerEl.addClass(classes.videoPlaying);
                }
            });

            vimeoPlayer.on('ended', function(e) {
                $containerEl.removeClass(classes.videoPlaying);
            });
        }
    },

    /**
     * This function sets up all the vimeo players on a page.
     * It also listens for events for each player, and resizes the players based on when the browser window is resized. 
     */
    setupPlayers: function() {
        var self = this;

        $(`.${classes.player}`).each(function() {

            var $playerEl = $(this); // a reference to the player div
            var container = $playerEl.parents(`.${classes.container}`).get(0); // the container div
            var $containerEl = $(container);
            let player; // the player object
            let videoDimensions = self.getVideoDimensions($containerEl); // calculated dimensions of the video

            self.setPlayerDimensions($playerEl, videoDimensions);

            $(window).on('resize', throttle(function() {
                let newVideoDimensions = self.getVideoDimensions($containerEl);
                self.setPlayerDimensions($playerEl, newVideoDimensions);
            }, 250));

            let playerOptions = self.getPlayerOptions($playerEl, videoDimensions);

            // set options and initialise video

            player = new Vimeo.default($playerEl, playerOptions);

            self.setupEventListeners($playerEl, $containerEl, player);
        });
    },

    /**
     * This function initialises the vimeo javascript file and calls the function to set up the players.
     * It will also exit the script if there are no vimeo players to be set up on the page. 
     */
    init: function() {

        var self = this;

        if ($(`.${classes.player}`).length == 0) {
            return false;
        }

        self.setupPlayers();
    }
};

module.exports = vimeoVideos;