module.exports = function (element, callback) {
    // Check if element is jQuery object and is more than one, if so loop through
    if (element instanceof jQuery && element.length > 1) {
        element.each(function (i, element) {
            checkIsImage(element, callback);
        });
        // Check if element is a HTMLCollection
    } else if (element instanceof HTMLCollection) {
        element.forEach(element => {
            checkIsImage(element, callback);
        });
        // Check if element is a HTMLElement or jQuery (AND EXISTS)
    } else if (element instanceof HTMLElement || (element instanceof jQuery && element.length > 0)) {
        checkIsImage(element, callback);
        // Element provided is not a valid element
    } else {
        console.log('onImageLoad function - element passed in is not a valid HTML or jQuery element');
    }
};

const checkIsImage = function (element, callback) {
    if (element instanceof jQuery) {
        element = element.get(0);
    }
    // Check is an element
    if (element instanceof HTMLImageElement) {
        checkLoad(element, callback);
    } else {
        // Log as the element parameter in is not an element
        console.log('checkIsImage function - element passed in is not an image');
    }
};

const checkLoad = function (element, callback) {
    if (element.complete || (element.readyState === 4) || (element.readyState === 'complete')) {
        if (callback && typeof (callback == 'function')) {
            callback();
        }
    } else {
        element.onload = function () {
            if (callback && typeof (callback == 'function')) {
                callback();
            }
        };
    }
};