const pageScroll = require('../utils/pageScroll');
const events = require('./events');

const classes = {
    filter: 'c-all-vehicles__filter',
    filterToggle: 'js-results-filter-toggle',
    filterClose: 'js-results-filter-close',
};

let responsiveMenuBreakpoint = 800;

var resultsFilter = {

    toggleFilter () {
        var self = this;
        $(`.${classes.filterToggle}`).on('click', function(e){
            // Click event to toggle mobile nav
            let $filterToggle = $(this);
            let $filter = $(`.${classes.filter}`);
            e.preventDefault();
            if ($filterToggle.attr('aria-expanded') != 'true') {
                pageScroll.lock();
                $filterToggle.attr('aria-expanded', 'true');
                $filter.removeClass('u-remove');
                $filter.addClass('u-block');

            } else {
                pageScroll.unlock();
                $filterToggle.attr('aria-expanded', 'false');
                $filter.removeClass('u-block');
                $filter.addClass('u-remove');
            }
            self.responsiveMenuOpen = !self.responsiveMenuOpen;
        });
    },
    closeFilter () {
        var self = this;
        $(`.${classes.filterClose}`).on('click', function(e){
            // Click event to toggle mobile nav
            let $filterToggle = $(`.${classes.filterToggle}`);
            let $filter = $(`.${classes.filter}`);
            e.preventDefault();
            pageScroll.unlock();
            $filterToggle.attr('aria-expanded', 'false');
            $filter.addClass('u-remove');
        });
    },

    checkResponsiveWidth () {
        var self = this;
        if (window.innerWidth >= responsiveMenuBreakpoint) {
            let $filterToggle = $(`.${classes.filterToggle}`);
            let $filter = $(`.${classes.filter}`);

            pageScroll.unlock();
            $filterToggle.attr('aria-expanded', 'false');
            $filter.removeClass('u-remove');
        }
    },

    init: function() {
        var self = this;

        self.toggleFilter();
        self.closeFilter();

        events.add('resize', self.checkResponsiveWidth);

    }
};

module.exports = resultsFilter;